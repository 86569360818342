import Image from "next/image";

export const Logo = () => {
  return (
    <Image
      height={0}
      width={0}
      alt="logo"
      src="/logo.svg"
      className="w-[100px] h-auto pointer-events-none"
      priority
    />
  );
};
