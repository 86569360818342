import { create } from "zustand";

type UserStore = {
  id: string;
  tryflyId: string;
  firstName: string;
  lastName: string;
  email: string;
  setUserData: (data: {
    id: string;
    tryflyId: string;
    firstName: string;
    lastName: string;
    email: string;
  }) => void;
};

export const useUserData = create<UserStore>((set) => ({
  id: "",
  tryflyId: "",
  firstName: "",
  lastName: "",
  email: "",
  setUserData: (data: {
    id: string;
    tryflyId: string;
    firstName: string;
    lastName: string;
    email: string;
  }) =>
    set({
      ...data,
    }),
}));
