import { UserRole } from "@/types/globals";
import type { JwtPayload, UserResource } from "@clerk/types";

export function checkUserRole(
  sessionClaims: JwtPayload | null,
  requiredRole: UserRole
) {
  return sessionClaims?.metadata.roles?.includes(requiredRole);
}
export function checkUserRoleClient(
  user:
    | {
        publicMetadata: {
          roles?: UserRole[];
        };
      }
    | null
    | undefined,
  requiredRole: UserRole
) {
  return user?.publicMetadata.roles?.includes(requiredRole);
}
