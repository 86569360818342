"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import db from "@/database";
import { User } from "@/database/schema";
import { useUserData } from "@/hooks/use-user-data";
import { cn, getInitials } from "@/lib/utils";
import { useAuth, useUser } from "@clerk/nextjs";
import { UserResource } from "@clerk/types";
import axios from "axios";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export function UserNav({ showName }: { showName?: boolean }) {
  const { user } = useUser();
  const { signOut } = useAuth();
  const router = useRouter();

  const userData = useUserData();

  const getUserInfo = async (user: UserResource | null | undefined) => {
    try {
      if (user) {
        const res = await axios.get(
          `${process.env.NEXT_PUBLIC_API_BASE_URL}/student`
        );

        const {
          data,
        }: {
          data: User | null;
        } = res;

        if (data)
          userData.setUserData({
            email: data.email,
            firstName: data.firstName ?? "",
            lastName: data.lastName ?? "",
            id: data.id,
            tryflyId: data.tryflyId,
          });
      } else {
        userData.setUserData({
          email: "",
          firstName: "",
          lastName: "",
          id: "",
          tryflyId: "",
        });
      }
    } catch (error) {
      console.log(error);
      userData.setUserData({
        email: "",
        firstName: "",
        lastName: "",
        id: "",
        tryflyId: "",
      });
    }
  };

  useEffect(() => {
    getUserInfo(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn("w-max items-center flex gap-x-2 hover:bg-transparent")}
        >
          {showName && (
            <p className="font-semibold text-base hover:text-white/90 text-white">
              {user?.fullName}
            </p>
          )}

          <div className="relative h-8 w-8 rounded-full">
            <Avatar className="h-8 w-8">
              <AvatarImage src={user?.imageUrl} alt="" />
              <AvatarFallback className="text-white bg-primary">
                {getInitials(user?.fullName)}
              </AvatarFallback>
            </Avatar>
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.fullName}</p>
            {userData?.tryflyId ? (
              <p className="text-sm text-slate-700">
                TryFly ID: {userData?.tryflyId}
              </p>
            ) : null}
            <p className="text-xs leading-none text-muted-foreground">
              {user?.emailAddresses[0].emailAddress}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {/* <DropdownMenuGroup>
          <DropdownMenuItem>
            Profile
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            Billing
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            Settings
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>New Team</DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator /> */}
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => signOut({ redirectUrl: "/" })}
        >
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
