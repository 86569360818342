"use client";

import { Button } from "@/components/ui/button";
import { checkUserRoleClient } from "@/lib/user-role";
import { useUser } from "@clerk/nextjs";
import { LogOut } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import NavbarItem from "./navbar-item";

interface RoutesProps {
  routes: {
    label: string;
    href: string;
  }[];
}

const NavbarRoutes = ({ routes }: RoutesProps) => {
  const pathname = usePathname();

  const isTeacherPage = pathname?.startsWith("/teacher");
  const isAdminPage = pathname?.startsWith("/admin");

  const { user } = useUser();
  const isTeacher = checkUserRoleClient(user, "teacher");
  const isAdmin = checkUserRoleClient(user, "teacher");

  return (
    <div className="hidden md:flex items-center text-muted-foreground">
      {routes.map((route) => (
        <NavbarItem key={route.href} label={route.label} href={route.href} />
      ))}

      {isTeacherPage || isAdminPage ? (
        <Link href={"/student"}>
          <Button
            size={"sm"}
            variant={"ghost"}
            className="font-medium  text-sm"
          >
            <LogOut className="h-4 w-4 mr-2" />
            Exit
          </Button>
        </Link>
      ) : (
        <>
          {isTeacher && (
            <Link href={"/teacher"}>
              <Button variant={"ghost"}>Teacher</Button>
            </Link>
          )}

          {isAdmin && (
            <Link href={"/admin"}>
              <Button variant={"ghost"}>Admin</Button>
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default NavbarRoutes;
