import { checkUserRoleClient } from "@/lib/user-role";
import { useUser } from "@clerk/nextjs";
import { AnimatePresence, motion } from "framer-motion";
import { LogOut } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import { UserNav } from "./user-nav";

interface RoutesProps {
  routes: {
    label: string;
    href: string;
  }[];
}

const MobileSidebar = ({ routes }: RoutesProps) => {
  const pathname = usePathname();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };
  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const isTeacherPage = pathname?.startsWith("/teacher");
  const isAdminPage = pathname?.startsWith("/admin");

  const { user } = useUser();

  const isTeacher = checkUserRoleClient(user, "teacher");
  const isAdmin = checkUserRoleClient(user, "teacher");

  // const sideDrawerClosedHandler = () => {
  //   setMobileMenuOpen(false);

  //   document.body.style.overflow = "unset";
  // };

  // const showSidebar = () => {
  //   setMobileMenuOpen(true);

  //   if (typeof window != "undefined" && window.document) {
  //     document.body.style.overflow = "hidden";
  //   }
  // };

  const toggleSidebar = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  return (
    <>
      <button
        className={`${styles["burger"]} ${
          mobileMenuOpen ? styles["active"] : ""
        } md:hidden flex flex-col items-center ml-2`}
        onClick={toggleSidebar}
      >
        <span></span>
        <p className="mt-3 font-medium text-xs text-primary">MENU</p>
      </button>

      <div className="md:hidden">
        <AnimatePresence>
          {mobileMenuOpen && (
            <motion.aside
              initial={{ translateX: "100%" }}
              animate={{ translateX: "0%" }}
              exit={{
                translateX: "100%",
                transition: { delay: 0.7, duration: 0.3 },
              }}
              className="fixed inset-0 top-16 py-20 bg-primary flex flex-col justify-between overflow-y-auto"
            >
              <nav className="">
                <motion.ul
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={sideVariants}
                  className="flex flex-col gap-7 px-4 text-right items-end"
                >
                  {routes.map((route) => (
                    <motion.li
                      key={route.href}
                      whileHover={{ scale: 1.05 }}
                      variants={itemVariants}
                      className={` font-semibold ${
                        pathname === route.href
                          ? "text-white text-4xl"
                          : "text-secondary text-3xl"
                      }`}
                    >
                      <Link
                        href={route.href}
                        className="relative p-2 px-4"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {route.label}
                      </Link>
                    </motion.li>
                  ))}

                  {isTeacherPage || isAdminPage ? (
                    <motion.li
                      whileHover={{ scale: 1.05 }}
                      variants={itemVariants}
                      className={`font-semibold text-white text-4xl`}
                    >
                      <Link
                        href={"/student"}
                        className="relative p-2 px-4 inline-flex items-center"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <LogOut className="h-8 w-8 mr-2" />
                        Exit
                      </Link>
                    </motion.li>
                  ) : (
                    <>
                      {isTeacher && (
                        <motion.li
                          whileHover={{ scale: 1.05 }}
                          variants={itemVariants}
                          className={` font-semibold text-secondary text-3xl`}
                        >
                          <Link
                            href={"/teacher"}
                            className="relative p-2 px-4"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            Teacher
                          </Link>
                        </motion.li>
                      )}

                      {isAdmin && (
                        <motion.li
                          whileHover={{ scale: 1.05 }}
                          variants={itemVariants}
                          className={`font-semibold text-secondary text-3xl`}
                        >
                          <Link
                            href={"/admin"}
                            className="relative p-2 px-4"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            Admin
                          </Link>
                        </motion.li>
                      )}
                    </>
                  )}
                </motion.ul>
              </nav>
              <div className="mt-auto pt-10 flex items-center text-secondary font-medium gap-x-2 justify-end p-2 px-4">
                <UserNav showName />
              </div>
            </motion.aside>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default MobileSidebar;
