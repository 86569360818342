import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface SidebarItemProps {
  href: string;
  label: string;
}

const NavbarItem = ({ href, label }: SidebarItemProps) => {
  const pathname = usePathname();

  const isActive =
    (pathname === "/" && href === "/") ||
    (pathname === "/teacher" && href === "/teacher") ||
    pathname === href ||
    (pathname.startsWith(`${href}/`) &&
      href !== "/teacher" &&
      href !== "/admin" &&
      href !== "/student");

  return (
    <Link href={href} className={cn("font-medium", isActive && "text-primary")}>
      <Button variant={"ghost"}>{label}</Button>
    </Link>
  );
};

export default NavbarItem;
